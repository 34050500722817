import React,{useEffect} from 'react';
import "./dataanalytics.css";
import CompanyName from "../component/companyname";
import Timeline from "../component/timeline";
import Benefits from "../component/benifits";
import  Price  from "../component/price";
import Accordion from "../component/accordian";
import LogoSlider from "../component/logoslider";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { saveAs } from 'file-saver';


export const Sas = () => {

  useEffect (() =>{
    Aos.init({duration: 2000,
      })
  },[])

  const handleDownload = () => {
    // Download the specified PDF file based on the selected year
    const taxFile = require(`../pdf/SAS syllabus.pdf`);
    saveAs(taxFile, `SAS syllabus.pdf`);
  };


  return (
    <div className="bg-waves">
      <CompanyName />

      <div className="d-flex flex-row justify-content-center mainboxxxx animation mb-4"  data-aos="zoom-in">
        <div className="mainheading d-flex flex-column justify-content-center ">
        
          <h4 className="mt-3">
          Unlock Your IT Career with Banking Statistical Analysis Software (SAS) Training <br />
          </h4>
          <span class="vibrate-1">
          100% Job Placement Support
            </span>
            <p className="mt-3">
            Transition Seamlessly from Non-IT to IT
            </p>
          <p >
          Ready to switch to a rewarding IT career in banking analytics? Our comprehensive SAS training program is designed just for you
         </p>
        </div>
      </div>

        <br/>

      <div className="d-flex flex-row justify-content-center animation" data-aos="fade-up">
        <button class="custom-button" type="button" onClick={handleDownload}>
          <span class="custom-button__text">SaS brochure</span>
          <span class="custom-button__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 35 35"
              id="bdd05811-e15d-428c-bb53-8661459f9307"
              data-name="Layer 2"
              class="custom-svg"
            >
              <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
              <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
              <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
            </svg>
          </span>
        </button>
      </div>

    <Timeline/>

    <br/>

    <div>
    <section class="course-overview" data-aos="fade-up">
        <div class="text-center">
            <h2>What You'll Learn</h2>
        </div>
        <ul class="learning-points">
            <li data-aos="fade-right">
                <h3>Introduction to SAS</h3>
                <p>Get a solid foundation in SAS programming and its applications in banking.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Data Management</h3>
                <p>Learn how to manage and manipulate large banking datasets.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Statistical Analysis</h3>
                <p>Perform detailed statistical analyses and interpret the results.</p>
            </li>
           
            <li data-aos="fade-right">
                <h3>Report Generation</h3>
                <p>Create comprehensive reports and dashboards to present your findings effectively.</p>
            </li>
        </ul>

        <div class="text-center">
            <h2>Who Should Enroll?</h2>
        </div>
        <ul class="learning-points">
            <li data-aos="fade-right">
                <h3>Non-IT Professionals</h3>
                <p>Individuals from finance, accounting, or any other non-IT background looking to switch to a tech role.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Graduates</h3>
                <p>Fresh graduates aiming to enter the banking analytics industry.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Career Changers</h3>
                <p>Anyone seeking to leverage their existing skills and transition into the IT sector with a focus on banking analytics.</p>
            </li>
        </ul>
    </section>
    </div>

    

   <div className='animation' data-aos="fade-up">
    <div className="text-center headlineeee">
        <h3>More than 50k recruiters from leading tech companies are hiring</h3>
    </div>

        <LogoSlider/>
    </div> 

    <div className='animation' data-aos="fade-up">
    <div className="text-center headlineeee">
        <h3>Placement benefits</h3>
    </div>

        <Benefits/>
    </div>


    <div className='animation ' data-aos="fade-up">
    <div className="text-center headlineeee mb-4">
        <h3>Plan and pricing</h3>
    </div>
        <Price ogprice="Rs.25,000/-" price="Rs.7,199/-" and="   and" onemonthsalary="One month's Salary after receiving the Offer Letter" redirections="https://makenowacademy.ongraphy.com/courses/Statistical-Analysis-Software-TrainingSAS-66823b7c63540d7c87fcbae8-66823b7c63540d7c87fcbae8"/>
    </div>

    <Accordion/>

    <footer className='animation site-footer mt-5' data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>About</h6>
            <p class="text-justify">MakeNow Academy is a cutting-edge institution dedicated to fostering innovation, creativity, and practical skill development in various domains such as technology, business, design, and more. Founded on the principle of empowering individuals to make an impact in today's fast-paced world, MakeNow Academy offers a range of programs tailored to meet the needs of aspiring professionals, entrepreneurs, and lifelong learners alike. We have trained 12K+ student across India, Australia and USA</p>
          </div>

          <div class="col-xs-12 col-md-6 text-center">
            <h6 style={{marginLeft:"20px"}} className="heartbeat">Get in Touch</h6>
            <input placeholder="Name" class="inputt" name="text" type="text"></input>
            <input placeholder="Phone Number" class="inputt" name="text" type="text"></input>
            <input placeholder="Email" class="inputt" name="text" type="text"></input>
            <button class="btnnn">Submit</button>
              </div>

        </div>
        <hr/>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text">Copyright &copy; 2024 All Rights Reserved by 
         <a href="https://makenow.in"> MakeNow</a>.
            </p>
          </div>

        </div>
      </div>
</footer>

    </div>
  );
};
