import './landingpage.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import { DataAnalytics } from './pages/dataanalytics';
import { WebDevelopment } from './pages/webdevelopmentt';
import Sidebar from './component/sidebar';
import { Python } from './pages/python';
import { Excel } from './pages/excel';
import { Clang } from './pages/c';
import { Sas } from './pages/sas';
import { Sql } from './pages/sql';
import ScrollToTop from './component/scrolltotop';
import { Fullstack } from './pages/fullstack';
import Chatbot from './component/chatbot';
import { Webinar } from './pages/webinar';
import { Webinarlist } from './pages/webinarlist';


function Landingpage() {
  const location = useLocation();

  return (
    <div className='bg-main'>
      {(location.pathname === '/' || location.pathname === '/WebDevelopment') && <Sidebar />}
      <Chatbot/>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<DataAnalytics />} />
        <Route path="/WebDevelopment" element={<WebDevelopment />} />
        <Route path="/python" element={<Python />} />
        <Route path="/excel" element={<Excel />} />
        <Route path="/c" element={<Clang />} />
        <Route path="/sas" element={<Sas />} />
        <Route path="/sql" element={<Sql />} />
        <Route path="/fullstack" element={<Fullstack />} />
        <Route path="/webinar" element={<Webinar />} />
        <Route path="/webinarlist" element={<Webinarlist />} />

      </Routes>
    </div>
  );
}

export default Landingpage;
