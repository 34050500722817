import React, { useEffect, useState, useRef } from "react";
import "./sidebar.css";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { IoIosMore } from "react-icons/io";

const Sidebar = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const moreOptionsRef = useRef(null);

  const getValueFromPath = (path) => {
    switch (path) {
      case '/':
        return 0;
      case '/WebDevelopment':
        return 1;
      case '/python':
        return 2;
      case '/excel':
        return 3;
      case '/sql':
        return 4;
      case '/sas':
        return 5;
      case '/c':
        return 6;
      default:
        return 0;
    }
  };

  const [value, setValue] = useState(useState(getValueFromPath(location.pathname)));


  useEffect(() => {
    const handleScroll = () => {
      const bottomThreshold = 100; // adjust this value as needed
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - bottomThreshold) {
        setSidebarVisible(false);
      } else {
        setSidebarVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (moreOptionsRef.current && !moreOptionsRef.current.contains(event.target)) {
        setShowMoreOptions(false);
      }
    };

    if (showMoreOptions) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMoreOptions]);

  const handleMoreClick = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  useEffect(() =>{
    console.log(value)
  },[value])

  const getBackgroundColor = (path) => {
    return location.pathname === path ? 'rgb(236, 129, 23)' : ''; // Set your desired background color here
  };

  return (
    <div>
      <nav className={`sidebar d-none d-md-block ${isSidebarVisible ? 'visible' : 'hidden'}`}>
        <ul>
          <Link to='/' style={{ fontSize: "13px", textDecoration: "none" }}>
            <li
              className={value === 0 ? 'active' : ''}
              onClick={() => setValue(0)}
              style={{ backgroundColor: getBackgroundColor('/') }}
            >
              <p className="heartbeat">
                Data <span style={{ fontSize: "11px" }}>ANALYTICS</span>
              </p>
            </li>
          </Link>

          <Link to='/WebDevelopment' style={{ textDecoration: "none" }}>
            <li
              className={value === 1 ? 'active' : ''}
              onClick={() => setValue(1)}
              style={{ backgroundColor: getBackgroundColor('/WebDevelopment') }}
            >
              <p style={{ fontSize: "14px" }} className="heartbeat">
                Web <span style={{ fontSize: "11px" }}>Development</span>
              </p>
            </li>
          </Link>

          
        </ul>
      </nav>

      {/* <Box sx={{ pb: 7 }} className="d-block d-md-none">
        <CssBaseline />
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
            zIndex: "1200"
          }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              if (newValue === 0) {
                navigate("/");
              } else if (newValue === 1) {
                navigate("/WebDevelopment");
              }
            }}
            sx={{ flexGrow: 1 }}
          >
            <BottomNavigationAction label="Data Analytics" />
            <BottomNavigationAction label="Web Development" />
          </BottomNavigation>
        </Paper>
        {showMoreOptions && (
          <Paper
            ref={moreOptionsRef}
            sx={{
              position: "fixed",
              bottom: "56px",
              left: 0,
              right: 0,
              background: "#212121",
              zIndex: 1000
            }}
            elevation={3}
          >
            <ul className="moreitems">
              <a href="https://makenow.in" target="_blank" style={{ textDecoration: "none" }}>
                <li style={{ color: "#fff", cursor: "pointer" }}>Login</li>
              </a>
              <li
                onClick={() => navigate("/python")}
                style={{ color: "#fff", cursor: "pointer" }}
              >
                Python
              </li>
              <li
                onClick={() => navigate("/excel")}
                style={{ color: "#fff", cursor: "pointer" }}
              >
                Excel
              </li>
              <li
                onClick={() => navigate("/sql")}
                style={{ color: "#fff", cursor: "pointer" }}
              >
                Sql
              </li>
              <li
                onClick={() => navigate("/sas")}
                style={{ color: "#fff", cursor: "pointer" }}
              >
                SaS
              </li>
              <li
                onClick={() => navigate("/c")}
                style={{ color: "#fff", cursor: "pointer" }}
              >
                C
              </li>
            </ul>
          </Paper>
        )}
      </Box> */}
    </div>
  );
};

export default Sidebar;
