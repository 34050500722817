import React,{useEffect} from 'react';
import "./dataanalytics.css";
import CompanyName from "../component/companyname";
import Timeline from "../component/timeline";
import Benefits from "../component/benifits";
import Accordion from "../component/accordian";
import LogoSlider from "../component/logoslider";
import Aos from 'aos';
import 'aos/dist/aos.css';
import logo from "../images/Makenow logo.png";
import pricepic from '../images/price.svg';
import { saveAs } from 'file-saver';



export const Fullstack = () => {

  useEffect (() =>{
    Aos.init({duration: 2000,
      })
  },[])

  const handleDownload = () => {
    // Download the specified PDF file based on the selected year
    const taxFile = require(`../pdf/Full Stack (1).pdf`);
    saveAs(taxFile, `Full Stack Development syllabus.pdf`);
  };

  const amount = 100*100000;
  const currency = "INR";
  const receiptId = "qwsaq1";


  const paymentHandler = async (e) => {
    const response = await fetch("https://api.bootcamp.makenow.in/order", {
      method: "POST",
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const order = await response.json();
    console.log(order);

    var options = {
      key: "rzp_live_2EWSvUx7aMmKbv", // Enter the Key ID generated from the Dashboard
      amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency,
      name: "MakeNow", //your business name
      description: "Test Transaction",
      image: logo,
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          ...response,
        };

        const validateRes = await fetch(
          "https://api.bootcamp.makenow.in/order/validate",
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const jsonRes = await validateRes.json();
        console.log(jsonRes);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: "", //your customer's name
        email: "",
        contact: "", //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
  };


  return (
    <div className="bg-waves">
      <CompanyName />

      <div className="d-flex flex-row justify-content-center mainboxxxx animation"  data-aos="zoom-in">
        <div className="mainheading d-flex flex-column justify-content-center ">
        
          <h4 className="mt-3">
          Become a Full Stack Developer with Our Job Guaranteed Program <br />
          Start earning a 4 LPA salary in just six months
          </h4>
          <span class="vibrate-1">
          100% Job Placement Support
            </span>
          <p className="mt-2">
          Unlock your potential with our comprehensive Full Stack Developer programming course. Ideal for beginners and professionals a like.
          </p>
        </div>
      </div>

        <br/>

      <div className="d-flex flex-row justify-content-center animation" data-aos="fade-up">
      <button class="custom-button" type="button" onClick={handleDownload}>
      <span class="custom-button__text">Full Stack Developer brochure</span>
          <span class="custom-button__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 35 35"
              id="bdd05811-e15d-428c-bb53-8661459f9307"
              data-name="Layer 2"
              class="custom-svg"
            >
              <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
              <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
              <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
            </svg>
          </span>
        </button>
      </div>

    <Timeline/>

    <br/>

    <div className="program-overview" data-aos="fade-up">
  <div className="container">
    <h3 className="title">Program Overview</h3>
    <p className="description">
      Briefly describe the course, its duration, and key features.
    </p>
    <div className="key-benefits">
      <h3 className="benefits-title">Key Benefits</h3>
      <div className="benefits-grid">
        <div className="benefit-card" data-aos="flip-left">
          <div className="icon-container">
            <i className="fas fa-briefcase icon"></i>
          </div>
          <div className="text-container">
            <p className="benefit-text">Early job placement (start working from the 2nd or 3rd month)</p>
          </div>
        </div>
        <div className="benefit-card" data-aos="flip-right">
          <div className="icon-container">
            <i className="fas fa-money-bill-wave icon"></i>
          </div>
          <div className="text-container">
            <p className="benefit-text">Guaranteed salary of 4 LPA after six months</p>
          </div>
        </div>
        <div className="benefit-card" data-aos="flip-left">
          <div className="icon-container">
            <i className="fas fa-chalkboard-teacher icon"></i>
          </div>
          <div className="text-container">
            <p className="benefit-text">Live classes with experienced instructors</p>
          </div>
        </div>
        <div className="benefit-card" data-aos="flip-right">
          <div className="icon-container">
            <i className="fas fa-project-diagram icon"></i>
          </div>
          <div className="text-container">
            <p className="benefit-text">Hands-on projects and real-world experience</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    <div>
    <section className="course-overview" data-aos="fade-up">
        <div className="text-center">
          <h2>What You'll Learn</h2>
        </div>
        <p>
          Our Full Stack Developer course is designed to take you from a beginner to an expert level. Here’s a detailed look at what you’ll cover:
        </p>
        <ul className="learning-points">
          <li data-aos="fade-right">
            <h3>Introduction to Web Development and Setup</h3>
            <p>Learn how to set up your development environment and the basics of web development.</p>
          </li>
          <li data-aos="fade-right">
            <h3>HTML and CSS</h3>
            <p>Understand the structure and styling of web pages.</p>
          </li>
          <li data-aos="fade-right">
            <h3>JavaScript and DOM Manipulation</h3>
            <p>Learn the fundamentals of JavaScript and how to manipulate the DOM.</p>
          </li>
          <li data-aos="fade-right">
            <h3>Front-End Frameworks: React.js</h3>
            <p>Dive into React.js to build dynamic and interactive user interfaces.</p>
          </li>
          <li data-aos="fade-right">
            <h3>Backend Development: Node.js and Express.js</h3>
            <p>Understand server-side development with Node.js and Express.js.</p>
          </li>
          <li data-aos="fade-right">
            <h3>Database Management: MySQL</h3>
            <p>Learn how to work with MySQL to store and manage data.</p>
          </li>
          <li data-aos="fade-right">
            <h3>RESTful APIs</h3>
            <p>Create and consume RESTful APIs to enable communication between the front-end and back-end.</p>
          </li>
          <li data-aos="fade-right">
            <h3>Authentication and Security</h3>
            <p>Implement authentication and secure your web applications.</p>
          </li>
          <li data-aos="fade-right">
            <h3>Deployment</h3>
            <p>Learn how to deploy your web applications to the cloud.</p>
          </li>
        </ul>
      </section>
    </div>

   <div className='animation' data-aos="fade-up">
    <div className="text-center headlineeee">
        <h3>More than 50k recruiters from leading tech companies are hiring</h3>
    </div>

        <LogoSlider/>
    </div> 

    <div className='animation' data-aos="fade-up">
    <div className="text-center headlineeee">
        <h3>Placement benefits</h3>
    </div>

        <Benefits/>
    </div>


    <div className='animation ' data-aos="fade-up">
    <div className="text-center headlineeee mb-4">
        <h3>Plan and pricing</h3>
    </div>
        <div className="d-flex flex-row justify-content-center pricebox">
        <div class="cardd">
        <div className="d-flex flex-row justify-content-center">
        <p class="ogprice ">
        Rs.1,50,000/-
        </p>
        <p className="price">
        Rs.1,00,000/-
        </p>
        </div>
        
        <div className="d-flex flex-row ">
            <div>
            <ul class="lists">
            <li class="list">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" d="M21.5821 5.54289C21.9726 5.93342 21.9726 6.56658 21.5821 6.95711L10.2526 18.2867C9.86452 18.6747 9.23627 18.6775 8.84475 18.293L2.29929 11.8644C1.90527 11.4774 1.89956 10.8443 2.28655 10.4503C2.67354 10.0562 3.30668 10.0505 3.70071 10.4375L9.53911 16.1717L20.1679 5.54289C20.5584 5.15237 21.1916 5.15237 21.5821 5.54289Z" clip-rule="evenodd" fill-rule="evenodd"></path> </g></svg>
            <span className="bounce-top"> Live Classes </span>
            </li> 
            <li class="list">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" d="M21.5821 5.54289C21.9726 5.93342 21.9726 6.56658 21.5821 6.95711L10.2526 18.2867C9.86452 18.6747 9.23627 18.6775 8.84475 18.293L2.29929 11.8644C1.90527 11.4774 1.89956 10.8443 2.28655 10.4503C2.67354 10.0562 3.30668 10.0505 3.70071 10.4375L9.53911 16.1717L20.1679 5.54289C20.5584 5.15237 21.1916 5.15237 21.5821 5.54289Z" clip-rule="evenodd" fill-rule="evenodd"></path> </g></svg>
                <span> Industry standard curriculum by expert </span>
            </li>
            <li class="list">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" d="M21.5821 5.54289C21.9726 5.93342 21.9726 6.56658 21.5821 6.95711L10.2526 18.2867C9.86452 18.6747 9.23627 18.6775 8.84475 18.293L2.29929 11.8644C1.90527 11.4774 1.89956 10.8443 2.28655 10.4503C2.67354 10.0562 3.30668 10.0505 3.70071 10.4375L9.53911 16.1717L20.1679 5.54289C20.5584 5.15237 21.1916 5.15237 21.5821 5.54289Z" clip-rule="evenodd" fill-rule="evenodd"></path> </g></svg>
            <span> 1:1 live doubt support (Unlimited) </span>
            </li>
            <li class="list">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" d="M21.5821 5.54289C21.9726 5.93342 21.9726 6.56658 21.5821 6.95711L10.2526 18.2867C9.86452 18.6747 9.23627 18.6775 8.84475 18.293L2.29929 11.8644C1.90527 11.4774 1.89956 10.8443 2.28655 10.4503C2.67354 10.0562 3.30668 10.0505 3.70071 10.4375L9.53911 16.1717L20.1679 5.54289C20.5584 5.15237 21.1916 5.15237 21.5821 5.54289Z" clip-rule="evenodd" fill-rule="evenodd"></path> </g></svg>
            <span>Dedicated relationship manager </span>
            </li>
            <li class="list">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" d="M21.5821 5.54289C21.9726 5.93342 21.9726 6.56658 21.5821 6.95711L10.2526 18.2867C9.86452 18.6747 9.23627 18.6775 8.84475 18.293L2.29929 11.8644C1.90527 11.4774 1.89956 10.8443 2.28655 10.4503C2.67354 10.0562 3.30668 10.0505 3.70071 10.4375L9.53911 16.1717L20.1679 5.54289C20.5584 5.15237 21.1916 5.15237 21.5821 5.54289Z" clip-rule="evenodd" fill-rule="evenodd"></path> </g></svg>
            <span>Dedicated, focused, personalised placement assistance. </span>
            </li>
            <li class="list">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" d="M21.5821 5.54289C21.9726 5.93342 21.9726 6.56658 21.5821 6.95711L10.2526 18.2867C9.86452 18.6747 9.23627 18.6775 8.84475 18.293L2.29929 11.8644C1.90527 11.4774 1.89956 10.8443 2.28655 10.4503C2.67354 10.0562 3.30668 10.0505 3.70071 10.4375L9.53911 16.1717L20.1679 5.54289C20.5584 5.15237 21.1916 5.15237 21.5821 5.54289Z" clip-rule="evenodd" fill-rule="evenodd"></path> </g></svg>
            <span> Peer Interaction and network building </span>
            </li>
        </ul>
            </div>

            <div className="d-none d-md-block">
               <img src={pricepic} alt="" className="pricepic"/> 
            </div>
        </div>
       
        <p target="_blank" class="action" style={{cursor:"pointer"}} onClick={paymentHandler}>
           Buy Now
        </p>
        </div>
    </div>
    </div>

    <Accordion/>

    <footer className='animation site-footer mt-5' data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>About</h6>
            <p class="text-justify">MakeNow Academy is a cutting-edge institution dedicated to fostering innovation, creativity, and practical skill development in various domains such as technology, business, design, and more. Founded on the principle of empowering individuals to make an impact in today's fast-paced world, MakeNow Academy offers a range of programs tailored to meet the needs of aspiring professionals, entrepreneurs, and lifelong learners alike. We have trained 12K+ student across India, Australia and USA</p>
          </div>

          <div class="col-xs-12 col-md-6 text-center">
            <h6 style={{marginLeft:"20px"}} className="heartbeat">Get in Touch</h6>
            <input placeholder="Name" class="inputt" name="text" type="text"></input>
            <input placeholder="Phone Number" class="inputt" name="text" type="text"></input>
            <input placeholder="Email" class="inputt" name="text" type="text"></input>
            <button class="btnnn">Submit</button>
              </div>

        </div>
        <hr/>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text">Copyright &copy; 2024 All Rights Reserved by 
         <a href="https://makenow.in"> MakeNow</a>.
            </p>
          </div>

        </div>
      </div>
</footer>

    </div>
  );
};
