import React, { useEffect, useState } from "react";
import './webinar.css';

export const Webinarlist = () => {
  const [registrations, setRegistrations] = useState([]);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const response = await fetch("https://api.bootcamp.makenow.in/registrations"); // replace with your server URL
        const data = await response.json();
        const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setRegistrations(sortedData);
      } catch (error) {
        console.error("Error fetching registrations:", error);
      }
    };

    fetchRegistrations();
  }, []);

  return (
    <div>
      <h2>Webinar Registrations</h2>
      <h4>Total Entries : {registrations.length}</h4>
      {registrations.length > 0 ? (
        
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>City</th>
              <th>Year of Passout</th>
              <th>Qualification</th>
              <th>Experience</th>
              <th>Registered Date</th>
            </tr>
          </thead>
          <tbody>
            {registrations.map((registration) => (
              <tr key={registration.id}>
                <td>{registration.name}</td>
                <td>{registration.phonenumber}</td>
                <td>{registration.email}</td>
                <td>{registration.city}</td>
                <td>{registration.year_of_passout}</td>
                <td>{registration.qualification}</td>
                <td>{registration.experience}</td>
                <td>{new Date(registration.submitted_at).toLocaleString()}</td> 
                </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No registrations found.</p>
      )}
    </div>
  );
};
