import React from "react";
import "./timeline.css";

const Timeline = () => {
  return (
    <div class="timeline-container">

    <div className="text-center bootcampheading animation headlineeee" data-aos="fade-up">
        <p>Bootcamp Journey</p>
        <h3>Immersive Learning: Elevate Your Skills with Live Classes</h3>
    </div>
      <div class="main-timeline">
        <div className='animation timeline' data-aos="fade-up">
          <div class="icon"></div>
          <div class="date-content">
            <div class="date-outer">
              <span class="date">
                <span class="month">🤩</span>
                <span class="year">Excited!</span>
              </span>
            </div>
          </div>
          <div class="timeline-content">
            <h5 class="title">Kick start your journey</h5>
            <p class="description">
            Orientation and welcome session<br/>
                Meet your peers<br/>
                Profile review
            </p>
          </div>
        </div>

        <div className='animation timeline' data-aos="fade-up">
          <div class="icon"></div>
          <div class="date-content">
            <div class="date-outer">
              <span class="date">
                <span class="month">😎</span>
                <span class="year">Confident</span>
              </span>
            </div>
          </div>
          <div class="timeline-content">
            <h5 class="title">Enroll in our master course <br/> and solve real problems</h5>
            <p class="description">
            Build real world projects <br/>
            Get doubts resolved via 1:1 <br/>
            Meet industry experts
            </p>
          </div>
        </div>

        <div className='animation timeline' data-aos="fade-up">
          <div class="icon"></div>
          <div class="date-content">
            <div class="date-outer">
              <span class="date">
                <span class="month">😇</span>
                <span class="year">Supported</span>
              </span>
            </div>
          </div>
          <div class="timeline-content">
            <h5 class="title">Experts join to help you prepare for interviews</h5>
            <p class="description">
            Resume and profile creation<br/>
            Mock interviews<br/>
            Project review
            </p>
          </div>
        </div>

        <div className='animation timeline' data-aos="fade-up">
          <div class="icon"></div>
          <div class="date-content">
            <div class="date-outer">
              <span class="date">
                <span class="month">🤓</span>
                <span class="year">Resilient</span>
              </span>
            </div>
          </div>
          <div class="timeline-content">
            <h5 class="title">Catch eye of your dream companies</h5>
            <p class="description">
            Daily jobs sent<br/>
            sent to mail highlights<br/>
            Alumni referrals
            </p>
          </div>
        </div>

        <div className='animation timeline' data-aos="fade-up">
          <div class="icon"></div>
          <div class="date-content">
            <div class="date-outer">
              <span class="date">
                <span class="month">🌝</span>
                <span class="year">Finally..</span>
              </span>
            </div>
          </div>
          <div class="timeline-content">
            <h5 class="title">Nail the Interview for your dream job</h5>
            <p class="description">
            Gain company insights <br/>
            Apply for higher offers
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
