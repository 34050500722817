import React from 'react';
import './benifits.css';

const Benefits = () => {
  return (
    <div className="benefits-container">
      <div className="benefits-card" data-aos="flip-left">
        <div className="benefits-box">
          <div className="benefits-content">
            <h2>01</h2>
            <h3>Profiles highlighted on on naukri.com</h3>
            <p>Make a distinct mark for yourself on India's leading job portal.</p>
            
          </div>
        </div>
      </div>

      <div className="benefits-card" data-aos="flip-left">
        <div className="benefits-box">
          <div className="benefits-content">
            <h2>02</h2>
            <h3>300+ monthly job opportunities</h3>
            <p>Get access to an abundance of job openings every month.</p>
           
          </div>
        </div>
      </div>

      <div className="benefits-card" data-aos="flip-left">
        <div className="benefits-box">
          <div className="benefits-content">
            <h2>03</h2>
            <h3>800+ monthly job openings</h3>
            <p>Complete the program faster and apply for job opportunities</p>
          </div>
        </div>
      </div>

      <div className="benefits-card" data-aos="flip-left">
        <div className="benefits-box">
          <div className="benefits-content">
            <h2>04</h2>
            <h3>Dedicated placement coach</h3>
            <p>Guiding and motivating you every step of the way.</p>
            
          </div>
        </div>
      </div>

      <div className="benefits-card" data-aos="flip-left">
        <div className="benefits-box">
          <div className="benefits-content">
            <h2>05</h2>
            <h3>50+ Members placement team</h3>
            <p>A dedicated team to help you get placed in your dream company.</p>
           
          </div>
        </div>
      </div>

      <div className="benefits-card" data-aos="flip-left" >
        <div className="benefits-box">
          <div className="benefits-content">
            <h2>06</h2>
            <h3>Jobs opening sent everyday</h3>
            <p>We send you job openings daily on your WhatsApp directly.</p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Benefits;
