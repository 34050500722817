import React from "react";
import logo from "../images/Makenow logo.png";

// CompanyName component
const CompanyName = () => {
  return (
    <div className="d-flex flex-row justify-content-evenly">

      <div className="d-none d-md-block" >
        <p >{""}</p>
      </div>

      <div className="d-flex">
        <div>
          <img src={logo} alt="Makenow" className="logo" />
        </div>
        <div>
          <p class="text-pop-up-top">MakeNow</p>
          <p className="captionss">Things Beyond...</p>
        </div>
      </div>

      <div className="d-none d-md-block">
      <a href="https://makenow.in" target="_blank">
      <button className="loginbtn mt-4">
        <span> Contact Us </span>
      </button>

      </a>
      </div>
    </div>
  );
};

export default CompanyName;
