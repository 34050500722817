import React,{useEffect} from 'react';
import "./dataanalytics.css";
import CompanyName from "../component/companyname";
import Timeline from "../component/timeline";
import Benefits from "../component/benifits";
import  Price  from "../component/price";
import Accordion from "../component/accordian";
import LogoSlider from "../component/logoslider";
import Aos from 'aos';
import 'aos/dist/aos.css';

export const Excel = () => {

  useEffect (() =>{
    Aos.init({duration: 2000,
      })
  },[])


  return (
    <div className="bg-waves">
      <CompanyName />

      <div className="d-flex flex-row justify-content-center mainboxxxx animation"  data-aos="zoom-in">
        <div className="mainheading d-flex flex-column justify-content-center ">
        
          <h4 className="mt-3">
          Master Excel: Your Gateway to Data Analysis and Reporting <br />
          </h4>
          <span class="vibrate-1">
          100% Job Placement Support
            </span>
          <p className="mt-2">
          Transform raw data into actionable insights. Learn Excel from basics to advanced techniques with live classes and secure a job in data analysis and reporting.          </p>
        </div>
      </div>

        <br/>

      <div className="d-flex flex-row justify-content-center animation" data-aos="fade-up">
        <button class="custom-button" type="button">
          <span class="custom-button__text">Excel brochure</span>
          <span class="custom-button__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 35 35"
              id="bdd05811-e15d-428c-bb53-8661459f9307"
              data-name="Layer 2"
              class="custom-svg"
            >
              <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
              <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
              <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
            </svg>
          </span>
        </button>
      </div>

    <Timeline/>

    <br/>

    <div>
    <section class="course-overview" data-aos="fade-up">
        <div class="text-center">
            <h2>What You'll Learn</h2>
        </div>
        <p>Our Excel course is designed to take you from a beginner to an expert level. Here’s a detailed look at what you’ll cover:</p>
        <ul class="learning-points">
            <li data-aos="fade-right">
                <h3>Introduction to Excel and setup</h3>
                <p>Learn how to navigate Excel and set up your workspace.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Data entry and formatting</h3>
                <p>Understand how to efficiently input and format data.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Formulas and functions</h3>
                <p>Master essential Excel functions for data analysis.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Data visualization</h3>
                <p>Create charts and graphs to visually represent data.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Pivot tables and Pivot charts</h3>
                <p>Analyze large datasets with ease.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Advanced data analysis</h3>
                <p>Use Excel for complex data manipulation and analysis.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Macros and VBA</h3>
                <p>Automate repetitive tasks to increase productivity.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Best practices for data reporting</h3>
                <p>Learn how to create accurate and professional reports.</p>
            </li>
        </ul>
    </section>
    </div>

   <div className='animation' data-aos="fade-up">
    <div className="text-center headlineeee">
        <h3>More than 50k recruiters from leading tech companies are hiring</h3>
    </div>

        <LogoSlider/>
    </div> 

    <div className='animation' data-aos="fade-up">
    <div className="text-center headlineeee">
        <h3>Placement benefits</h3>
    </div>

        <Benefits/>
    </div>


    <div className='animation ' data-aos="fade-up">
    <div className="text-center headlineeee mb-4">
        <h3>Plan and pricing</h3>
    </div>
        <Price/>
    </div>

    <Accordion/>

    <footer className='animation site-footer mt-5' data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>About</h6>
            <p class="text-justify">MakeNow Academy is a cutting-edge institution dedicated to fostering innovation, creativity, and practical skill development in various domains such as technology, business, design, and more. Founded on the principle of empowering individuals to make an impact in today's fast-paced world, MakeNow Academy offers a range of programs tailored to meet the needs of aspiring professionals, entrepreneurs, and lifelong learners alike. We have trained 12K+ student across India, Australia and USA</p>
          </div>

          <div class="col-xs-12 col-md-6 text-center">
            <h6 style={{marginLeft:"20px"}} className="heartbeat">Get in Touch</h6>
            <input placeholder="Name" class="inputt" name="text" type="text"></input>
            <input placeholder="Phone Number" class="inputt" name="text" type="text"></input>
            <input placeholder="Email" class="inputt" name="text" type="text"></input>
            <button class="btnnn">Submit</button>
              </div>

        </div>
        <hr/>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text">Copyright &copy; 2024 All Rights Reserved by 
         <a href="https://makenow.in"> MakeNow</a>.
            </p>
          </div>

        </div>
      </div>
</footer>

    </div>
  );
};
