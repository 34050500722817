import React,{useEffect} from 'react';
import "./dataanalytics.css";
import CompanyName from "../component/companyname";
import Timeline from "../component/timeline";
import Benefits from "../component/benifits";
import  Price  from "../component/price";
import Accordion from "../component/accordian";
import LogoSlider from "../component/logoslider";
import Aos from 'aos';
import 'aos/dist/aos.css';

export const Python = () => {

  useEffect (() =>{
    Aos.init({duration: 2000,
      })
  },[])


  return (
    <div className="bg-waves">
      <CompanyName />

      <div className="d-flex flex-row justify-content-center mainboxxxx animation"  data-aos="zoom-in">
        <div className="mainheading d-flex flex-column justify-content-center ">
        
          <h4 className="mt-3">
          Become a Python Pro: Learn Python from Scratch <br />
          </h4>
          <span class="vibrate-1">
          100% Job Placement Support
            </span>
          <p className="mt-2">
          Unlock your potential with our comprehensive Python programming course. Ideal for beginners and professionals a like.
          </p>
        </div>
      </div>

        <br/>

      <div className="d-flex flex-row justify-content-center animation" data-aos="fade-up">
        <button class="custom-button" type="button">
          <span class="custom-button__text">Python brochure</span>
          <span class="custom-button__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 35 35"
              id="bdd05811-e15d-428c-bb53-8661459f9307"
              data-name="Layer 2"
              class="custom-svg"
            >
              <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
              <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
              <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
            </svg>
          </span>
        </button>
      </div>

    <Timeline/>

    <br/>

    <div>
    <section class="course-overview" data-aos="fade-up">
    <div className='text-center'>
    <h2 >What You'll Learn</h2>
    </div>
        <p>Our Python course is designed to take you from a beginner to an expert level. Here’s a detailed look at what you’ll cover:</p>
        <ul class="learning-points">
            <li data-aos="fade-right">
                <h3>Introduction to Python and setup</h3>
                <p>Learn how to install Python and set up your programming environment.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Data types, variables, and control flow</h3>
                <p>Understand the core building blocks of Python programming.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Functions and modules</h3>
                <p>Learn to write reusable code and organize your programs effectively.</p>
            </li>
            <li data-aos="fade-right">
                <h3>File handling and exception management</h3>
                <p>Master reading from and writing to files, and handling errors gracefully.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Working with libraries like NumPy and Pandas</h3>
                <p>Dive into data analysis with powerful Python libraries.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Data visualization with Matplotlib</h3>
                <p>Create compelling visualizations to communicate your data insights.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Basics of web scraping and APIs</h3>
                <p>Extract data from websites and interact with web services.</p>
            </li>
            <li data-aos="fade-right">
                <h3>Introduction to machine learning with Scikit-Learn</h3>
                <p>Get started with machine learning and build your first models.</p>
            </li>
        </ul>
    </section>
    </div>

   <div className='animation' data-aos="fade-up">
    <div className="text-center headlineeee">
        <h3>More than 50k recruiters from leading tech companies are hiring</h3>
    </div>

        <LogoSlider/>
    </div> 

    <div className='animation' data-aos="fade-up">
    <div className="text-center headlineeee">
        <h3>Placement benefits</h3>
    </div>

        <Benefits/>
    </div>


    <div className='animation ' data-aos="fade-up">
    <div className="text-center headlineeee mb-4">
        <h3>Plan and pricing</h3>
    </div>
        <Price/>
    </div>

    <Accordion/>

    <footer className='animation site-footer mt-5' data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>About</h6>
            <p class="text-justify">MakeNow Academy is a cutting-edge institution dedicated to fostering innovation, creativity, and practical skill development in various domains such as technology, business, design, and more. Founded on the principle of empowering individuals to make an impact in today's fast-paced world, MakeNow Academy offers a range of programs tailored to meet the needs of aspiring professionals, entrepreneurs, and lifelong learners alike. We have trained 12K+ student across India, Australia and USA</p>
          </div>

          <div class="col-xs-12 col-md-6 text-center">
            <h6 style={{marginLeft:"20px"}} className="heartbeat">Get in Touch</h6>
            <input placeholder="Name" class="inputt" name="text" type="text"></input>
            <input placeholder="Phone Number" class="inputt" name="text" type="text"></input>
            <input placeholder="Email" class="inputt" name="text" type="text"></input>
            <button class="btnnn">Submit</button>
              </div>

        </div>
        <hr/>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text">Copyright &copy; 2024 All Rights Reserved by 
         <a href="https://makenow.in"> MakeNow</a>.
            </p>
          </div>

        </div>
      </div>
</footer>

    </div>
  );
};
