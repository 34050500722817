import React, { useState, useEffect, useRef } from 'react';
import './chatbot.css'; // Import your CSS file
import { FaWindowClose } from "react-icons/fa";
import axios from 'axios';


const Chatbot = () => {
    const initialMessage = { text: 'Hi! How can I help you today ? Please enter your Name', isBot: true };
    const initialSuggestions = [
        { label: 'Course Information', value: 'Course Information' },
        { label: 'Contact Information', value: 'contact information' },
    ];

    const [messages, setMessages] = useState([initialMessage]);
    const [input, setInput] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [dynamicSuggestions, setDynamicSuggestions] = useState([]);
    const [chatbotVisible, setChatbotVisible] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [stage, setStage] = useState('collectingName'); // New state for managing the flow
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');

    // Create a reference for scrolling
    const messagesEndRef = useRef(null);

    useEffect(() => {
        setDynamicSuggestions(initialSuggestions);
    }, []);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (input.trim() === '') return;

        if (stage === 'collectingName') {
            setUserName(input);
            setMessages((prevMessages) => [...prevMessages, { text: `Nice to meet you, ${input}. What's your phone number?`, isBot: true }]);
            setInput('');
            setStage('collectingPhone');
        } else if (stage === 'collectingPhone') {
            const phoneNumber = parseInt(input, 10);
            if (!isNaN(phoneNumber) && input.length >= 10) {
                setUserPhone(input);
                try {
                    // Send the collected name and phone number to the backend
                    await axios.post('https://api.bootcamp.makenow.in/user_info', { name: userName, phone: input });
                    setMessages((prevMessages) => [...prevMessages, { text: `Thank you for providing your phone number. How can I assist you today?`, isBot: true }]);
                    setInput('');
                    setStage('showingSuggestions');
                    setShowSuggestions(true); // Show suggestions after collecting user info
                    setPhoneError(''); // Clear any previous error
                } catch (error) {
                    console.error('Error storing user info', error);
                    setMessages((prevMessages) => [...prevMessages, { text: 'There was an error storing your information. Please try again.', isBot: true }]);
                }
            } else {
                setPhoneError('Phone number must be an integer and more than 10 digits.');
            }
        } else {
            setMessages((prevMessages) => [...prevMessages, { text: input, isBot: false }]);
            setInput('');

            setIsTyping(true);
            setShowSuggestions(false); // Hide suggestions immediately after submitting
            setTimeout(() => {
                handleBotResponse(input.toLowerCase());
            }, 1000);
        }
    };

    const handleBotResponse = (inputText) => {
        let response = '';
        let newSuggestions = [];

        switch (inputText) {
            case 'hello':
            case 'hi':
                response = 'Hello! How can I assist you today?';
                newSuggestions = initialSuggestions;
                break;
            case 'course information':
                response = 'Sure, which product would you like information about?';
                newSuggestions = [
                    { label: 'Laptop', value: 'laptop' },
                    { label: 'Smartphone', value: 'smartphone' },
                ];
                break;
            case 'laptop':
                response = 'Great! What specific details do you want to know about laptops?';
                newSuggestions = [
                    { label: 'Price', value: 'price' },
                    { label: 'Model', value: 'model' },
                ];
                break;
            case 'price':
                response = 'Our pricing varies based on the product. We have offers. Select an offer.';
                newSuggestions = [
                    { label: '50%', value: '50%' },
                    { label: '60%', value: '60%' },
                ];
                break;
            case '50%':
                response = '50% offer applied';
                newSuggestions = [
                    { label: 'Anything else', value: 'anything else' },
                    { label: 'End Chat', value: 'end chat' },
                ];
                break;
            case 'contact information':
                response = 'Email id - MakeNow@gmail.com';
                newSuggestions = [
                    { label: 'Anything else', value: 'anything else' },
                    { label: 'End Chat', value: 'end chat' },
                ];
                break;
            case 'end chat':
                response = 'Bye';
                newSuggestions = [{ label: 'New Chat', value: 'new chat' }];
                break;
            case 'new chat':
                setMessages([initialMessage]);
                newSuggestions = initialSuggestions;
                setShowSuggestions(true);
                response = initialMessage.text;
                break;
            default:
                response = "I apologize; that was not clear to me. Use the suggestions, please.";
                newSuggestions = dynamicSuggestions;
                break;
        }

        setTimeout(() => {
            setMessages((prevMessages) => [...prevMessages, { text: response, isBot: true }]);
            setDynamicSuggestions(newSuggestions);
            setShowSuggestions(true);
            setIsTyping(false);
        }, 1000);
    };

    const handleSuggestionClick = (suggestion) => {
        setMessages((prevMessages) => [...prevMessages, { text: suggestion, isBot: false }]);
        setIsTyping(true);
        setShowSuggestions(false); // Hide suggestions immediately after clicking
        setTimeout(() => {
            handleBotResponse(suggestion.toLowerCase());
        }, 1000);
    };

    return (
        <div className="app-container">
            {!chatbotVisible && (
                <button className="toggle-chatbot" onClick={() => setChatbotVisible(true)}>
                    Chatbot
                </button>
            )}
            <div className={`support-chat-container ${chatbotVisible ? 'visible' : 'hidden'}`}>
                <div className="support-chat">
                    <div className="laundry-chat-header">
                        <h1 className="laundry-chat-title">Chat Bot</h1>
                        <p onClick={() => setChatbotVisible(!chatbotVisible)}>
                            <FaWindowClose style={{ color: "red", fontSize: "20px", marginRight: "10px" }} />
                        </p>
                    </div>
                    <div className="chat-messages">
                        {messages.map((msg, index) => (
                            <div key={index} className={msg.isBot ? 'bot-message' : 'user-message'}>
                                {msg.text}
                            </div>
                        ))}
                        {isTyping && <div className="bot-message typing">Bot is typing...</div>}
                        <div ref={messagesEndRef} />
                    </div>
                    {phoneError && <div className="phone-error">{phoneError}</div>}
                    {showSuggestions && stage === 'showingSuggestions' && (
                        <div className="suggestion-buttons">
                            {dynamicSuggestions.map((button, index) => (
                                <button key={index} onClick={() => handleSuggestionClick(button.value)}>
                                    {button.label}
                                </button>
                            ))}
                        </div>
                    )}
                    {(stage === 'collectingName' || stage === 'collectingPhone') && (
                        <form onSubmit={handleSubmit} className="chat-input">
                            <input
                                type="text"
                                value={input}
                                onChange={handleInputChange}
                                placeholder={stage === 'collectingName' ? "Enter your name..." : "Enter your phone number..."}
                            />
                            <button type="submit">Submit</button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Chatbot;
