import React, { useEffect, useState } from "react";
import "./dataanalytics.css";
import CompanyName from "../component/companyname";
import Aos from "aos";
import "aos/dist/aos.css";
import "./webinar.css";
import { saveAs } from "file-saver";

export const Webinar = () => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phonenumber: "",
    email: "",
    city: "",
    year_of_passout: "",
    qualification: "",
    experience: "",
  });

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const handleJoinWebinarClick = () => {
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://api.bootcamp.makenow.in/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg);
      }
  
      const data = await response.json();
      console.log(data.msg);
  
      setFormData({
        name: "",
        phonenumber: "",
        email: "",
        city: "",
        year_of_passout: "",
        qualification: "",
        experience: "",
      });
  
      // Redirect after successful submission
      window.open("https://meet.google.com/wqi-phkj-sgy", "_blank");
    } catch (error) {
      console.error("Error:", error);
      alert(error.message); // Show alert with the specific error message
    }
  };
  

  const handleDownload = () => {
    const taxFile = require(`../pdf/Data Analytics mastery.pdf`);
    saveAs(taxFile, `Data Analytics mastery.pdf`);
  };

  return (
    <div>
      <div
        className={`webinarcontainer ${isFormVisible ? "blur-background" : ""}`}
      >
        <CompanyName />

        <div
          className="d-flex flex-row justify-content-center mainboxxxx animation"
          data-aos="zoom-in"
        >
          <div className="mainheading d-flex flex-column justify-content-center webinarboxx">
            <h4 className="mt-3">
              Unlock Your Future in Data Analytics <br />
            </h4>
            <br />
            <p className="mt-2">
              Join Our Exclusive Webinar with Industry Experts - Start Your
              Journey to a Rewarding Career
              <br /> <b>Today At 8 pm Sharp!</b>
            </p>

            <br />
            <br />

            <div>
              <button
                className="webinarbutton"
                onClick={handleJoinWebinarClick}
              >
                Join Webinar
              </button>
            </div>
            <br />

            <br />
            <div>
              <button
                className="webinarbutton"
                onClick={handleDownload}
              >
                Download Sylabus
              </button>
            </div>
          </div>
        </div>
      </div>

      {isFormVisible && (
        <div className="webinar-form-container">
          <button className="close-button" onClick={handleCloseForm}>
            &times;
          </button>
          <p className="webinar-title">Complete the form to join the webinar</p>
          <form className="webinar-form" onSubmit={handleSubmit}>
            <input
              type="text"
              className="webinar-input"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              type="number"
              className="webinar-input"
              name="phonenumber"
              placeholder="Phone Number"
              value={formData.phonenumber}
              onChange={handleInputChange}
            />
            <input
              type="email"
              className="webinar-input"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="text"
              className="webinar-input"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
            />
            <input
              type="text"
              className="webinar-input"
              name="year_of_passout"
              placeholder="Year of Passout"
              value={formData.year_of_passout}
              onChange={handleInputChange}
            />
            <input
              type="text"
              className="webinar-input"
              name="qualification"
              placeholder="Qualification"
              value={formData.qualification}
              onChange={handleInputChange}
            />
            <input
              type="text"
              className="webinar-input"
              name="experience"
              placeholder="Experience (if any)"
              value={formData.experience}
              onChange={handleInputChange}
            />
            <button className="webinar-form-btn" type="submit">
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};
