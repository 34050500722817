import React, { useState } from 'react';
import styled from 'styled-components';
import fotterpic from '../images/fotter.svg';

const AccordionContainer = styled.div`
  .container-fluid {
    padding-top: 50px;
  }


  .container-fluid h2 {
    font-weight: 600;
    font-style: normal;
  }

  

  .source {
    padding-top: 50px;
    font-size: 0.75em;
    text-align: center;
  }

  details {
    position: relative;
    width: 100%;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary::before {
    content: "";
    position: absolute;
    left: 0;
    width: 1.5em;
    height: 1.5em;
    transition: transform 0.1s linear;
  }

  summary {
    width: 100%;
    padding: 20px;
    padding-left: 25px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }

  summary:hover {
    color: rgb(236, 129, 23);
  }

  summary:focus {
    outline: none;
  }

  details[open] summary:before {
    transform: rotate(90deg);
  }

  details[open] > summary {
    color: rgb(236, 129, 23);
  }

  details[open] > summary ~ * {
    animation: open 1s ease;
  }

  @keyframes open {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Accordion = () => {
  const [openIndexes, setOpenIndexes] = useState([]);

  const toggle = (index, event) => {
    event.preventDefault();
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter(i => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  return (
    <AccordionContainer>
    <div className='bgftr'>
    <div className="container-fluid headlineeee">
        <div className="row">
          <div className="col-10 offset-1 ">
          <div style={{ textAlign: 'center' }}>
          <h3 >Frequently Asked Questions</h3>
          </div>
            <br />
            <details open={openIndexes.includes(0)} onClick={(e) => toggle(0, e)} className="animation" data-aos="fade-up">
              <summary>What is Job Bootcamp?</summary>
              <p style={{marginLeft:"27px"}}>Job Bootcamp is one of a kind program designed to enhance your technical skills from a novice to an expert as a data analyst in just 6 months.</p>
            </details>

            <details open={openIndexes.includes(1)} onClick={(e) => toggle(1, e)} className="animation" data-aos="fade-up">
              <summary>What is the refund period?</summary>
              <p style={{marginLeft:"27px"}}>You can claim a full fee refund within the first 14 days of the batch formation. The initial booking amount of INR 5000 and the remaining tuition fee will be refunded. After 14 days, you will not be able to raise a refund request before your graduation.</p>
            </details>

            <details open={openIndexes.includes(2)} onClick={(e) => toggle(2, e)} className="animation" data-aos="fade-up">
              <summary>What are the prerequisites before applying for Job Bootcamp?</summary>
              <p style={{marginLeft:"27px"}}>Anyone who is a working professional with a degree/final year student regardless of industry or domain are eligible for Job Bootcamp.</p>
            </details>

            <details open={openIndexes.includes(3)} onClick={(e) => toggle(3, e)} className="animation" data-aos="fade-up">
              <summary>What is the duration of the Job Bootcamp?</summary>
              <p style={{marginLeft:"27px"}}>The duration of the program is 6 months (learning phase) along with placement support till you get your first job.</p>
            </details>

            <details open={openIndexes.includes(4)} onClick={(e) => toggle(4, e)} className="animation" data-aos="fade-up">
              <summary>How good is the placement record?</summary>
              <p style={{marginLeft:"27px"}}>As per our past records, 90% of learners who completed our program secured suitable job opportunities. Makenow Academy has consistently kept its promises and is a trusted brand in the education industry, helping 7,000 members double their salaries. We will create your profile, prepare your resume, conduct mock interviews, and do our utmost to help you land a tech job.</p>
            </details>

            <details open={openIndexes.includes(5)} onClick={(e) => toggle(5, e)} className="animation" data-aos="fade-up">
              <summary>How will I benefit from Industry Mentors?</summary>
              <p style={{marginLeft:"27px"}}>Industry mentors are experts from reputed companies with proven competencies in their fields, making them the best guides for your career advancement. They will conduct 20 live 1:1 interactive mock interview sessions to prepare you for success in the industry.</p>
            </details>

            <details open={openIndexes.includes(6)} onClick={(e) => toggle(6, e)} className="animation" data-aos="fade-up">
              <summary>Which roles will I be eligible for?</summary>
              <p style={{marginLeft:"27px"}}>With the help of Data Analysis Bootcamp, you will be eligible for a multitude of job roles like Analytics Consultant, Business Analyst, Data Engineer, ML Engineer, Data Analyst, Data Science Consultant, Data Visualization Analyst, MIS Analyst, Statistical Analyst, Data Scientist in the Industry.</p>
            </details>

            <details open={openIndexes.includes(7)} onClick={(e) => toggle(7, e)} className="animation" data-aos="fade-up">
              <summary>How do I get my doubts resolved?</summary>
              <p style={{marginLeft:"27px"}}>At Makenow Academy, we ensure all your doubts are addressed in our live classes, held daily for one hour. Our expert instructors will clear any questions you have at the end of each session. With personalized attention and consistent support, we are committed to your success and confident in helping you achieve your career goals.</p>
            </details>
          </div>
        </div>
      </div>
    </div>
     
    </AccordionContainer>
  );
};

export default Accordion;
