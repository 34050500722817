import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./logoslider.css";
import logo1 from "../images/logos/bookmyshow-logo_brandlogos.net_rzqea.png";
import logo2 from "../images/logos/facebook.png";
import logo3 from "../images/logos/Rapido-Logo_2020.png";
import logo4 from "../images/logos/shopping.png";
import logo5 from '../images/logos/1mg.png';
import logo6 from '../images/logos/Freecharge_logo.png';
import logo7 from '../images/logos/Hike_Logo.png';
import logo8 from '../images/logos/Meesho-Logo.png';
import logo9 from '../images/logos/shopping.png';
import logo10 from '../images/logos/1mg.png';
import logo11 from '../images/logos/smartcoin_.png';
import logo12 from '../images/logos/OYO_Rooms_logo.png';
import logo13 from '../images/logos/Paytm_logo.png';
import logo14 from '../images/logos/Meesho-Logo.png';
import logo15 from '../images/logos/Rapido-Logo_2020.png';
import logo16 from '../images/logos/shopping.png';
import logo17 from '../images/logos/upstox-logo.png';

const LogoSlider = () => {

  return (
    <div class="logo-slider mb-4">
      <div class="logo-slide-track">
        <div class="slide">
          <img src={logo1} alt="" />
        </div>
        <div class="slide">
          <img src={logo2} alt="" />
        </div>
        <div class="slide">
          <img src={logo3} alt="" />
        </div>
        <div class="slide">
          <img src={logo4} alt="" />
        </div>
        <div class="slide">
          <img src={logo5} alt="" />
        </div>
        <div class="slide">
          <img src={logo6} alt="" />
        </div>
        <div class="slide">
          <img src={logo7} alt="" />
        </div>
        <div class="slide">
          <img src={logo8} alt="" />
        </div>
        <div class="slide">
          <img src={logo9} alt="" />
        </div>
        <div class="slide">
          <img src={logo10} alt="" />
        </div>
        <div class="slide">
          <img src={logo11} alt="" />
        </div>
        <div class="slide">
          <img src={logo12} alt="" />
        </div>
        <div class="slide">
          <img src={logo13} alt="" />
        </div>
        <div class="slide">
          <img src={logo14} alt="" />
        </div>
        <div class="slide">
          <img src={logo15} alt="" />
        </div>
        <div class="slide">
          <img src={logo16} alt="" />
        </div>
        <div class="slide">
          <img src={logo17} alt="" />
        </div>
      </div>
    </div>
  );
};

export default LogoSlider;
